import { createContext, useState, useEffect } from "react";
import * as auth from "./auth";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [usage, setUsage] = useState(0);
  const [maxFileSize, setMaxFileSize] = useState(0);
  const [maxSpace, setMaxSpace] = useState(0);
  const [region, setRegion] = useState(null);
  const [idpool, setIdpool] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [demo, setDemo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [cloudinary_cloud, setCloudinary_cloud] = useState(null);
  const [cloudinary_api_key, setCloudinary_api_key] = useState(null);
  const [cloudinary_api_secret, setCloudinary_api_secret] = useState(null);
  const [cloudinary_preset, setCloudinary_preset] = useState(null);

  const getCurrentUser = async () => {
    try {
      const data = await auth.getCurrentUser();
      setUser(data[0]);
      setAccessToken(data[1]);
      setIdToken(data[2]);
      setIsAdmin(data[3]);
    } catch (err) {
      // not logged in
      console.log(err);
      setUser(null);
    }
  };

  useEffect(() => {
    console.log("AuthContext useEffect")
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const signIn = async (username, password) => {
    await auth.signIn(username, password);
    await getCurrentUser();
  };

  const signInAndOut = async (username, password) => {
    await auth.signInAndOut(username, password);
  };

  const signOut = async () => {
    await auth.signOut();
    setUser(null);
    setAccessToken(null);
    setIdToken(null);
  };

  const authValue = {
    user,
    setUser,
    usage,
    setUsage,
    maxSpace,
    setMaxSpace,
    maxFileSize,
    setMaxFileSize,
    region,
    setRegion,
    idpool,
    setIdpool,
    accessToken,
    idToken,
    jwt,
    setJwt,
    demo,
    setDemo,
    isLoading,
    signIn,
    signOut,
    signInAndOut,
    isAdmin, setIsAdmin,
    adminMode, setAdminMode,
    cloudinary_cloud,setCloudinary_cloud,
    cloudinary_api_key,setCloudinary_api_key,
    cloudinary_api_secret,setCloudinary_api_secret,
    cloudinary_preset,setCloudinary_preset
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
