import React from 'react';
import PropTypes from 'prop-types';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
//import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePopover } from '../hooks/use-popover';
import { AccountPopover } from './account-popover';
import { NotificationPopover } from './notification-popover';

import { styled } from '@mui/material/styles';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 68;

const StyledIconButton = styled(IconButton)({
  backgroundColor: '#0174BE',
  '&:hover': {
    backgroundColor: '#005c96', // Adjust hover background color
  },
  '&.Mui-disabled': {
    backgroundColor: '#9DA4AE', // Use theme color or custom color
  },
});

export const TopNav = React.memo((props) => {
  const { onNavOpen, language, setLanguage, projects, loading } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();
  const notificationPopover = usePopover();
  const { user, adminMode } = useContext(AuthContext);

  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (!projects || projects.length === 0) return; // Early exit if projects is empty or not loaded

    //console.log('useEffect top-nav.js - projects:', projects);

    const count = projects.reduce((acc, project) => {
      const projectData = project.attributes.data.data || [];
      //console.log(`Project has ${projectData.length} data items`);

      projectData.forEach((dataItem, dataIndex) => {
        const resultsData = dataItem.attributes.results.data || [];
        //console.log(`  Data item ${dataIndex} has ${resultsData.length} results`);

        resultsData.forEach((resultItem, resultIndex) => {
          //console.log(`    Result item ${resultIndex} viewed status: ${resultItem.attributes.viewed}`);
          if (resultItem.attributes.viewed === false) { // Only count if viewed is explicitly `false`
            acc++;
          }
        });
      });
      return acc;
    }, 0);

    //console.log(`Total notification count calculated: ${count}`);
    if (notificationCount !== count) {
      setNotificationCount(count); // Only update if count has changed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const handleNotificationUpdate = (count) => {
    setNotificationCount(count);
  };

  //const { t } = useTranslation();

  function stringAvatar(user) {
    if (user) {
      if (user.name.split(' ').length > 1) {
        return {
          children: `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`.toUpperCase(),
        };
      }

      if (user.name.split(' ').length === 1) {
        return {
          children: `${user.name.split(' ')[0][0]}`.toUpperCase(),
        };
      } else {
        return ''
      }
    }
  }

  return (
    <>
      <Box
        component="header"
        sx={{
          backgroundColor: 'transparent',
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          {loading &&
            <Box sx={{ px: 2 }}>
              <Stack
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                direction="row"
                spacing={2}
              >
                <CircularProgress size="2rem" />
                <Typography color="text.secondary" variant="h6">
                Fetching data
                </Typography>
              </Stack>
            </Box>
          }
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!adminMode && <StyledIconButton sx={{
              bgcolor: '#0174BE',
              cursor: 'pointer',
              height: 40,
              width: 40,
              transition: 'all 0.3s ease-in-out',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.14)',
                transform: 'scale(1.05)',
              },
              '&:active': {
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.24)',
                transform: 'scale(0.95)',
              },
            }}
              disabled={notificationCount === 0}
              onClick={notificationPopover.handleOpen}
              ref={notificationPopover.anchorRef}>
              <Badge
                badgeContent={notificationCount}
                color="error"
              >
                <SvgIcon>
                  <NotificationsNoneOutlinedIcon style={{ color: 'white' }} />
                </SvgIcon>
              </Badge>
            </StyledIconButton>}
            <Avatar
              {...stringAvatar(user)}
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                bgcolor: '#0174BE',
                cursor: 'pointer',
                height: 40,
                width: 40
              }} />
          </Stack>
        </Stack>
      </Box>

      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        language={language}
        setLanguage={setLanguage}
      />
      <NotificationPopover
        anchorEl={notificationPopover.anchorRef.current}
        open={notificationPopover.open}
        onClose={notificationPopover.handleClose}
        projects={projects}
        onUpdateNotificationCount={handleNotificationUpdate}
      />
    </>

  );
});

TopNav.propTypes = {
  onNavOpen: PropTypes.func
};
