import * as React from 'react';
import { useState, useContext } from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import PasswordChecklist from "react-password-checklist"

import { AuthContext } from "../Auth/AuthContext";

export default function NewUserDialog({ setOpen, open, handleNewUserCreated }) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    const { idToken, signInAndOut } = useContext(AuthContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");

    const [passwordValid, setPasswordValid] = useState(false)

    const mystyle = {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        wordWrap: 'break-word'
    }

    const handleClose = () => {
        setName("");
        setEmail("");
        setCompany("");
        setPassword("");
        setOpen(false);
    };

    const handleCreate = () => {
        
        if(!isLoading){

            setIsLoading(true);

            if (!name) {
                enqueueSnackbar(`${t("warningNameNewUser")}`, {variant:'warning'} );
                setIsLoading(false);
                return;
            }

            if (!company) {
                enqueueSnackbar(`${t("warningCompanyNewUser")}`, {variant:'warning'} );
                setIsLoading(false);
                return;
            }

            // Email validation using a regular expression
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                enqueueSnackbar(`${t("warningEmailNewUser")}`, {variant:'warning'} );
                setIsLoading(false);
                return;
            }

            if (!passwordValid) {
                enqueueSnackbar(`${t("warningPasswordNewUser")}`, {variant:'warning'} );
                setIsLoading(false);
                return;
            }

            setOpen(false);
            newUser(name, email, company, password);
        }
    };

    function newUser(_name, _email, _company, _password) {
        const dataForm = new FormData();
        dataForm.append('data', JSON.stringify({
            name: _name,
            email: _email,
            company: _company,
            password: _password
        }))
        axios.post(global.USER_CREATION_API_URL, dataForm, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            }
        }).then(res => {
            console.log(res);
            signInAndOut(_email, _password).then(() => {
                //console.log("User signed in and out successfully, should now appear in CMS.");
                handleNewUserCreated();
                setIsLoading(false);
                setName("");
                setEmail("");
                setCompany("");
                setPassword("");
                enqueueSnackbar(`${t("successCreateNewUser")}`, {variant:'success'} );
              }).catch((err) => {
                console.error("Sign-in failed:", err);
                setIsLoading(false);
                setName("");
                setEmail("");
                setCompany("");
                setPassword("");
                enqueueSnackbar(`${t("errorCreateNewUser")}`, {variant:'error'} );
              });
        })
        .catch(err => {
            console.log(err);
            setIsLoading(false);
            setName("");
            setEmail("");
            setCompany("");
            setPassword("");
            enqueueSnackbar(`${t("errorCreateNewUser")}`, {variant:'error'} );
        });
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("NewUser")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("NewUserDescription")}
                            </Typography>
                        </DialogContentText>
                        <Stack spacing={3} sx={{ mt: 3 }}>
                            <TextField
                                fullWidth
                                label={t("Name")}
                                name="Name"
                                type="text"
                                value={name}
                                onChange={(event) => { setName(event.target.value) }}
                            />
                            <TextField
                                fullWidth
                                label={t("Email")}
                                name="Email"
                                type="text"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                            />
                            <TextField
                                fullWidth
                                label={t("Company")}
                                name="Company"
                                type="text"
                                value={company}
                                onChange={(event) => { setCompany(event.target.value) }}
                            />
                            <TextField
                                fullWidth
                                label={t("Password")}
                                name="Password"
                                type="text"
                                value={password}
                                onChange={(event) => { setPassword(event.target.value) }}
                            />
                            <Box sx={{ pl: 1 }}>
                                <PasswordChecklist
                                    style={mystyle}
                                    rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                    minLength={8}
                                    value={password}
                                    messages={{
                                        minLength: t("minLengthPW"),
                                        specialChar: t("specialCharPW"),
                                        number: t("numberPW"),
                                        capital: t("capitalPW"),
                                        lowercase: t("lowercasePW"),
                                    }}
                                    onChange={(isValid) => { setPasswordValid(isValid) }}
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button variant="contained" onClick={handleCreate}>{isLoading ? `${t("Creating")} ...` : `${t("Create")}`}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}