import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { cognitoConfig } from "./cognitoConfig";
import axios from "axios";

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});

export function signUp(username, email, password) {
  return new Promise((resolve, reject) => {
    userPool.signUp(
      username,
      password,
      [{ Name: "email", Value: email }],
      null,
      (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result.user);
      }
    );
  });
}

export function confirmSignUp(username, code) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function signIn(username, password) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.email;

        // Get these details and call
        cognitoUser.completeNewPasswordChallenge(password, userAttributes, this);
      },
    });
  });
}

export function signInAndOut(username, password) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        //console.log("User signed in successfully.");

        // Retrieve the session tokens
        cognitoUser.getSession(async (err, session) => {
          if (err) {
            //console.error("Error getting session:", err);
            reject(err);
            return;
          }

          if (session.isValid()) {
            const idToken = session.getIdToken().getJwtToken();
            const accessToken = session.getAccessToken().getJwtToken();
            //console.log("Session is valid. ID and Access tokens retrieved.");

            // Make a GET request to Strapi to create the user in its database
            try {
              let request = `${global.API_URL}/api/auth/cognito/callback?id_token=${idToken}&access_token=${accessToken}`;
              await axios.get(request);
              //console.log("User created in Strapi:", res.data);

              // Sign out the user immediately after the user is created in Strapi
              cognitoUser.signOut();
              resolve("User signed in, registered in Strapi, and signed out.");
            } catch (strapiError) {
              //console.error("Error creating user in Strapi:", strapiError);
              cognitoUser.signOut();
              reject(strapiError);
            }
          } else {
            reject(new Error("Session is invalid."));
          }
        });
      },
      onFailure: (err) => {
        console.error("Authentication failed:", err);
        reject(err);
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        delete userAttributes.email_verified;
        delete userAttributes.email;

        cognitoUser.completeNewPasswordChallenge(password, userAttributes, this);
      },
    });
  });
}

export function forgotPassword(username) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function confirmPassword(username, confirmationCode, newPassword) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function signOut() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

function isUserAdministrator(session) {
  // Decode the payload of the access token to retrieve group information
  const groups = session.getIdToken().decodePayload()["cognito:groups"];

  // Check if the "administrator" group is present in the groups array
  return groups && groups.includes("administrator");
}

export async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {});

        resolve([{ ...userData, username: cognitoUser.username },session.accessToken.jwtToken,session.idToken.jwtToken,isUserAdministrator(session)]);
      });
    });
  });
}

export function getSession() {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    });
  });
}
