import * as React from 'react';
import Button from '@mui/material/Button';
import { SvgIcon } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

export default function ShareFileDialog({ url, setOpen, open }) {

    const {  t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleCreateLink = () => {
        navigator.clipboard.writeText(url);
        enqueueSnackbar(`${t("LinkSuccess")}`, {variant:'success'} );
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
            <Box sx={{ p: 3 }}>
                <DialogTitle>
                    <Typography variant="h4" component={"div"}>
                        {t("ShareFile")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"}>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {t("ShareFileDesc")}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                    <Button 
                    startIcon={(
                        <SvgIcon fontSize="medium">
                            <ShareIcon />
                        </SvgIcon>
                    )} variant="contained" onClick={handleCreateLink}>{t("CreateLink")}</Button>
                </DialogActions>
            </Box>
        </Dialog>
        </>
    );
}