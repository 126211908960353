import * as React from 'react';
import axios from "axios";
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import Button from '@mui/material/Button';
import { SvgIcon } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { FileUploader } from "react-drag-drop-files";

import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import ImageIcon from '@mui/icons-material/Image';
import { alpha } from '@mui/material/styles';
import AttachmentIcon from '@mui/icons-material/Attachment';

export default function EditFileDialog({ setOpen, open, data, reloadProjects }) {

    const {  t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {jwt, cloudinary_cloud, cloudinary_preset} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const [imageSelected, setImageSelected] = React.useState("");
    const imageTypes = ["PNG", "png", "JPG", "jpg", "JPEG", "jpeg"];
    const handleImageChange = (file) => {
        //console.log(file);
        setImageSelected(file);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setDescriptionNew(data.attributes.description);
    }, [data]);

    //console.log(data);
    const [descriptionNew, setDescriptionNew] = useState(data.attributes.description);

    const uploadImage = async (imageSelected) => {
        const formData = new FormData();
        formData.append("file", imageSelected);
        formData.append("upload_preset", cloudinary_preset);
        
        const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloudinary_cloud}/image/upload`, formData);
        return response.data.secure_url;
    };
    
    const updateData = async (description, imageUrl = null) => {
        const dataForm = new FormData();
        const dataNew = {
            description: description,
        };
        if (imageUrl) {
            dataNew.imageUrl = imageUrl;
        }
        console.log(data)
        dataForm.append('data', JSON.stringify(dataNew));
        
        await axios.put(`${global.API_URL}/api/datas/${data.id}`, dataForm, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        });
    };
    
    const handleEdit = async () => {
        setIsLoading(true);
        try {
            let optimizeImage = null;
    
            if (typeof imageSelected.name === 'string') {
                optimizeImage = await uploadImage(imageSelected);
            }
    
            await updateData(descriptionNew, optimizeImage);
    
            setOpen(false);
            enqueueSnackbar(`${t("SuccessEditFile")}`, { variant: 'success' });
            reloadProjects();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(`${t("ErrorEditFile")}`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("EditData")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("PleaseModifyData")}
                            </Typography>
                        </DialogContentText>
                        <Stack spacing={3} sx={{ mt: 3 }}>
                            <Stack spacing={1}>
                            <Typography
                            color="text.secondary"
                            variant="h6"
                            >
                            {t("NewDescription")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="description"
                                type="text"
                                multiline
                                rows={3}
                                value={descriptionNew}
                                onChange={(event) => { setDescriptionNew(event.target.value) }}
                            />
                            </Stack>
                            <Stack spacing={1}>
                            <Typography
                            color="text.secondary"
                            variant="h6"
                            >
                            {t("Image")}
                            </Typography>
                            <FileUploader
                                    handleChange={handleImageChange}
                                    multiple={false}
                                    name="image"
                                    types={imageTypes}
                                    hoverTitle=" "
                                    children={
                                        <Box>
                                            <Paper variant="outlined"
                                                sx={{
                                                    borderColor: '#E5E7EB',
                                                    borderStyle: 'solid',
                                                    borderWidth: 1,
                                                    '&:hover': {
                                                        backgroundColor: alpha('#111927', 0.04)
                                                    }
                                                }}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ minHeight: '100px', padding: 20 }}
                                                >

                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <ImageIcon style={{ color: 'gray' }} /><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Paper>
                                        </Box>}
                                />
                                </Stack>
                                {imageSelected &&
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                    >
                                        <AttachmentIcon style={{ color: 'gray' }} />
                                        <Typography variant="body2" color="text.secondary"><b>{imageSelected.name}</b></Typography>
                                    </Stack>}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button startIcon={(
                        <SvgIcon fontSize="medium">
                            <SaveIcon />
                        </SvgIcon>
                    )} 
                    variant="contained" onClick={handleEdit}>{isLoading ? `${t("Saving")} ...` : `${t("Save")}`}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}