import * as React from 'react';
import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { SvgIcon } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { AuthContext } from "../Auth/AuthContext";
import { FileUploader } from "react-drag-drop-files";
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { alpha } from '@mui/material/styles';

export default function NewDataDialog({ setOpen, open, projectSelected, userId, reloadProjects, addToUploadQueue }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { maxFileSize } = useContext(AuthContext);

  const [fileSelected, setFileSelected] = React.useState("");
  const [description, setDescription] = useState("");
  const [imageSelected, setImageSelected] = React.useState("");

  const fileTypes = ["LAS", "LAZ", "las", "laz"];
  const handleChange = (file) => {
    setFileSelected(file);
  };

  const imageTypes = ["PNG", "png", "JPG", "jpg", "JPEG", "jpeg"];
  const handleImageChange = (file) => {
    setImageSelected(file);  
  };

  const handleTypeError = () => {
    enqueueSnackbar(`${t("ErrorType")}`, { variant: 'warning' });
  };

  const handleClose = () => {
    setOpen(false);
    setFileSelected("");
    setImageSelected("");
  };

  function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  const handleCreate = () => {
    // Validate file selection
    if (!fileSelected) {
      enqueueSnackbar(`${t("ErrorNoFile")}`, { variant: 'warning' });
      return;
    }

    // Check for whitespace in the filename
    if (hasWhiteSpace(fileSelected.name)) {
      enqueueSnackbar(`${t("ErrorFileNoSpace")}`, { variant: 'warning' });
      return;
    }

    // Prepare the upload item
    const uploadItem = {
      file: fileSelected,
      imageFile: imageSelected,
      description: description,
    };

    // Pass the upload item to addToUploadQueue
    addToUploadQueue([uploadItem]);

    // Reset the form and close the dialog
    setOpen(false);
    setFileSelected("");
    setImageSelected("");
    setDescription("");
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
        <Box sx={{ p: 3 }}>
          <DialogTitle>
            <Typography variant="h4" component={"div"}>
              {t("UploadData")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component={"div"}>
              <Typography
                color="text.secondary"
                variant="body2"
              >
                {t("SelectALASFile")}
              </Typography>
            </DialogContentText>
            <Stack spacing={2} sx={{ mt: 3 }}>
              {/* File Uploader for LAS/LAZ Files */}
              <Stack spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                >
                  {t("LASLAZFile")}
                </Typography>
                <FileUploader
                  handleChange={handleChange}
                  onTypeError={handleTypeError}
                  multiple={false}
                  name="file"
                  types={fileTypes}
                  hoverTitle=" "
                  maxSize={maxFileSize * 1000}
                  children={
                    <Box>
                      <Paper variant="outlined"
                        sx={{
                          borderColor: '#E5E7EB',
                          borderStyle: 'solid',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: alpha('#111927', 0.04)
                          }
                        }}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          style={{ minHeight: '100px', padding: 20 }}
                        >
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                          >
                            <CloudUploadIcon style={{ color: 'gray' }} /><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                          </Stack>
                        </Grid>
                      </Paper>
                    </Box>}
                />
                {fileSelected &&
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <AttachmentIcon style={{ color: 'gray' }} />
                    <Typography variant="body2" color="text.secondary"><b>{fileSelected.name}</b></Typography>
                  </Stack>}
              </Stack>  
              {/* Description Field */}
              <Stack spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                >
                  {t("Description")}
                </Typography>
                <TextField
                  fullWidth                            
                  name="description"
                  type="text"
                  multiline
                  rows={3}
                  value={description}
                  onChange={(event) => { setDescription(event.target.value) }}
                />
              </Stack> 
              {/* File Uploader for Image */}
              <Stack spacing={1}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                >
                  {t("Image")}
                </Typography>
                <FileUploader
                  handleChange={handleImageChange}
                  multiple={false}
                  name="image"
                  types={imageTypes}
                  hoverTitle=" "
                  maxSize={10}
                  children={
                    <Box>
                      <Paper variant="outlined"
                        sx={{
                          borderColor: '#E5E7EB',
                          borderStyle: 'solid',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: alpha('#111927', 0.04)
                          }
                        }}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          style={{ minHeight: '100px', padding: 20 }}
                        >
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                          >
                            <ImageIcon style={{ color: 'gray' }} /><Typography variant="body2" color="text.secondary">{t("dragndrop")}</Typography>
                          </Stack>
                        </Grid>
                      </Paper>
                    </Box>}
                />
                {imageSelected &&
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <AttachmentIcon style={{ color: 'gray' }} />
                    <Typography variant="body2" color="text.secondary"><b>{imageSelected.name}</b></Typography>
                  </Stack>}
              </Stack>     
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
            <Button
            startIcon={(
              <SvgIcon fontSize="medium">
                  <FileUploadIcon  />
              </SvgIcon>
          )}  
            variant="contained" onClick={handleCreate}>{t("Upload")}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
