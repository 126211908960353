import CircleStackIcon from '@heroicons/react/24/solid/CircleStackIcon';
import ClockIcon from '@heroicons/react/24/solid/ClockIcon';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVert from "@mui/icons-material/MoreVert";
import {formatSizeUnits} from '../utils/fileManagement'
import { FileIcon } from '../fileIcon/FileIcon';
import defaultStyles from '../fileIcon/defaultStyles';
import Chip from '@mui/material/Chip';

import { usePopover } from '../hooks/use-popover';
import { FilePopoverAdmin } from './file-popover-admin';

export default function FileCardAdmin({ data, userid }) {

    const smUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));

    const filePopover = usePopover();

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    }

    const handleClickVertButton = (event) => {
        filePopover.handleOpen();
    };

    return (
        <>
            <Card         
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}            
            >
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        height: 120,
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        },                       
                    }}
                    action={
                        <div>
                             <IconButton aria-label="settings" onClick={handleClickVertButton} ref={filePopover.anchorRef}>
                             <MoreVert />
                            </IconButton> 
                        </div>
                    }
                    title={                 
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h6"
                        >
                            {data.filename }
                        </Typography>}
                    subheader={
                        <Typography
                            align="center"
                            variant="body1"
                            color="text.secondary"
                        >
                            {data.description}
                        </Typography>
                    }
                />
                 {smUp &&                 
                  <>
                 {
                 data.imageUrl ?
                 <Box
                 sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    px: 2, pb:0
                    }}
                >

                 <CardMedia
                    image={data.imageUrl}
                    sx={{
                    borderRadius: "20px",
                    width: "100%",
                    height: 0,
                    paddingBottom: "min(75%, 240px)",
                    backgroundColor: "rgba(0,0,0,0.08)",
                    boxShadow: '0 5px 22px rgba(0, 0, 0, 0.3)',
                    }}
                /> 
                </Box>
                 
                 :
          
                 <CardContent>
                 <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            px: 10
                        }}
                >
                <Box
                            sx={{
                                maxWidth:'80px'
                            }}
                        >
                        <FileIcon extension={`${data.filename.split('.').pop()}`} {...defaultStyles[`${data.filename.split('.').pop()}`]} />
                    </Box></Box>
                </CardContent> 
                }
                </> }
                {!smUp && 
                <Box sx={{ p: 1 }}></Box>
                }
                <Box sx={{ flexGrow: 1 }} />
                {!data.imageUrl && <Divider />}
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        {data.viewedByAdmin === false && <Chip label="New" color="error"></Chip>}
                        <SvgIcon
                            color="action"
                            fontSize="small"
                        >
                            <ClockIcon />
                        </SvgIcon>
                        <Typography
                            color="text.secondary"
                            display="inline"
                            variant="body2"
                        >
                            {getFormattedDate(data.createdAt)}
                        </Typography>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <SvgIcon
                            color="action"
                            fontSize="small"
                        >
                            <CircleStackIcon />
                        </SvgIcon>
                        <Typography
                            color="text.secondary"
                            display="inline"
                            variant="body2"
                        >
                            {formatSizeUnits(data.size)}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
            <FilePopoverAdmin
                anchorEl={filePopover.anchorRef.current}
                open={filePopover.open}
                onClose={filePopover.handleClose}
                data={data}
                userid={userid}
            />
        </>
    );
};