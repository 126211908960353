import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from "react-i18next";

export const ProjectPopover = (props) => {
  const { anchorEl, onClose, open, handleClickOnUploadProject, handleClickOnEditProject, handleClickOnDeleteProject } = props;

  const {  t } = useTranslation();

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 160 } }}
      >
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >
          <MenuItem onClick={(event) => handleClickOnUploadProject(event)}>
            <ListItemIcon>
              <FileUploadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Upload")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={(event) => handleClickOnEditProject(event)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Edit")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={(event) => handleClickOnDeleteProject(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

ProjectPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
