import * as React from 'react';
import { useState, useContext } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import axios from "axios";
import Button from '@mui/material/Button';
import { SvgIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import {deleteFileS3} from "../utils/interfaceS3";

export default function DeleteProjectDialog({ setOpen, open, projectSelected, reloadProjects }) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {user,idToken,jwt, region, idpool} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const deleteEntry = value => {
        setIsLoading(true);

        //first we delete every data attached to the project
        if(projectSelected.attributes.data !== undefined){
            //console.log("projectSelected",projectSelected);
            for(let i = 0; i < projectSelected.attributes.data.data.length; i += 1){
                let f = projectSelected.attributes.data.data[i];
                deleteFileS3(user.sub,idToken, region, idpool,`${f.attributes.filename}`);
                const apiUrl = `${global.API_URL}/api/datas/${f.id}`;
                axios.delete(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    }
                }).then(res => {
                    console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }

        const apiUrl = `${global.API_URL}/api/projects/${projectSelected.id}`;
        axios.delete(apiUrl, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            }
        })
            .then(res => {
                setIsLoading(false);
                setOpen(false);
                enqueueSnackbar(`${t("successDeleteProject")}`, {variant:'success'} );
                reloadProjects();
            })
            .catch(err => {
                setIsLoading(false);
                enqueueSnackbar(`${t("errorDeleteProject")}`, {variant:'error'} );
            });
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
                <Box sx={{ p: 3 }}>
                    <DialogTitle>
                        <Typography variant="h4" component={"div"}>
                            {t("DeleteProject")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("DeleteProjectDesc")}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="inherit">{t("Cancel")}</Button>
                        <Button                     
                        startIcon={(
                        <SvgIcon fontSize="medium">
                            <DeleteIcon />
                        </SvgIcon>
                    )} variant="contained" color="error" onClick={deleteEntry}>{isLoading ? `${t("Deleting")} ...` : `${t("Delete")}`}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}