import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';

import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 70 }}>
          <Typography variant="body1" color="text.secondary">Loading</Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 45 }}>
          <Typography variant="body1" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const ThreeDialog = ({ open, onClose, url }) => {

    const {  t } = useTranslation();

    const mountRef = useRef(null);
    const rendererRef = useRef(null);
    const [sceneInitialized, setSceneInitialized] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const initializeScene = () => {

            if (!mountRef.current) return;

            const mount = mountRef.current;

            // Create a scene
            const scene = new THREE.Scene();
            scene.background = new THREE.Color(0xffffff);

            // Create a camera
            const camera = new THREE.PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 1000);
            camera.lookAt(0,0,0)
            camera.up.set(0, -1, 0);
            camera.position.z = 5;

            // Create a renderer
            const renderer = new THREE.WebGLRenderer();
            renderer.setSize(mount.clientWidth, mount.clientHeight);
            mount.appendChild(renderer.domElement);
            rendererRef.current = renderer;

            const controls = new GaussianSplats3D.OrbitControls(camera, renderer.domElement);
            controls.listenToKeyEvents(window);
            controls.rotateSpeed = 0.5;
            controls.maxPolarAngle = Math.PI * .75;
            controls.minPolarAngle = 0.1;
            controls.enableDamping = true;
            controls.dampingFactor = 0.05;
            controls.target.copy(new THREE.Vector3(0,0,0));
            

            const viewer = new GaussianSplats3D.DropInViewer({
                //'halfPrecisionCovariancesOnGPU': false,
                //'antialiased': false,
                'sharedMemoryForWorkers': false
            });
            scene.add(viewer);

            console.log('adding the scene',url)

            const onProgress = (event) => {
                setProgress(event);
            };

            viewer.addSplatScene(url, {
                'format': 2,
                //'splatAlphaRemovalThreshold': 10,
                //'freeIntermediateSplatData': true,
                'progressiveLoad':true,
                'showLoadingUI':false,
                'onProgress':onProgress
            });

            // Animation loop
            const animate = () => {
                if (rendererRef.current) {
                    requestAnimationFrame(animate);
                    renderer.render(scene, camera);
                    controls.update()
                }
            };

            animate();

            // Handle window resize
            const handleResize = () => {
                if (mountRef.current && rendererRef.current) {
                    const { clientWidth, clientHeight } = mountRef.current;
                    rendererRef.current.setSize(clientWidth, clientHeight);
                    camera.aspect = clientWidth / clientHeight;
                    camera.updateProjectionMatrix();
                }
            };
            window.addEventListener('resize', handleResize);

            // Clean up on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
                if (rendererRef.current) {
                    mount.removeChild(rendererRef.current.domElement);
                    rendererRef.current.dispose();
                    rendererRef.current = null;
                }
            };
        };

        if (open && url && !sceneInitialized) {
            // Delay the initialization to ensure the Dialog has been fully rendered
            setTimeout(() => {
                initializeScene();
                setSceneInitialized(true);
            }, 100);
        }
    }, [open,url,sceneInitialized]);

    useEffect(() => {
        if (!open) {
            // Reset the sceneInitialized state when the dialog is closed
            setSceneInitialized(false);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
            <div ref={mountRef} style={{ height: '100vh', width: '100%' }}></div>
            <AppBar color="transparent" position="static">
                <Toolbar>
                    {progress<100 && <Box sx={{ width: '60vw', pr:'10px', marginLeft: "auto" }}>
                        <LinearProgressWithLabel variant="determinate" value={progress} />
                    </Box>}
                    <Button onClick={onClose} variant="contained" sx={{ marginLeft: "auto" }}>{t("Close")}</Button>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default ThreeDialog;