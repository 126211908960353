// UploadQueue.js

import React, { useState } from 'react';
import { Paper, Typography, Box, IconButton, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Close, ExpandLess, ExpandMore, CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { uploadS3, fileExistsInS3 } from "../utils/interfaceS3";
import { newActivity } from "../utils/activityManagement";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: theme.palette.mode === 'light' ? '#0174BE' : '#308fe8',
    },
  }));

function UploadQueue(props) {
  const {
    user,
    idToken,
    jwt,
    region,
    idpool,
    cloudinary_cloud,
    cloudinary_preset,
    projectSelected,
    reloadProjects,
    userId,
  } = props;

  const {  t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [uploadQueue, setUploadQueue] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isUploadQueueOpen, setIsUploadQueueOpen] = useState(true);
  const hasOngoingUploads = uploadQueue.some(item => item.status === 'uploading');

  const addToUploadQueue = (uploadItems) => {
    const newUploads = uploadItems.map((item) => ({
      id: uuidv4(),
      fileName: item.file.name,
      progress: 0,
      status: 'uploading',
      file: item.file,
      imageFile: item.imageFile,
      description: item.description,
    }));

    setUploadQueue((prevQueue) => [...prevQueue, ...newUploads]);

    // Start uploading each file
    newUploads.forEach((uploadItem) => uploadFile(uploadItem));
  };

  const uploadFile = async (uploadItem) => {

    setIsVisible(true)

    const { id, file, imageFile, description } = uploadItem;

    try {
      // Check if the file already exists in S3
      const fileExists = await fileExistsInS3(user.sub, idToken, file.name);
      if (fileExists) {
        enqueueSnackbar(`${t("ErrorAlreadyExists")}`, { variant: 'warning' });
        // Update status to error
        setUploadQueue((prevQueue) =>
          prevQueue.map((item) =>
            item.id === id ? { ...item, status: 'error' } : item
          )
        );
        return;
      }

      // Upload image to Cloudinary if imageFile is provided
      let optimizeImage = null;
      if (imageFile) {
        optimizeImage = await uploadImage(imageFile);
      }

      await uploadS3(user.sub, idToken, region, idpool, file.name, file, (progressEvent) => {
        const progress = progressEvent; // Use progressEvent directly
        // Update progress
        setUploadQueue((prevQueue) =>
          prevQueue.map((item) =>
            item.id === id ? { ...item, progress } : item
          )
        );
      });

      // Save data to backend
      const dataForm = createDataForm(file.name, file.size, description, optimizeImage);
      const response = await saveData(dataForm);

      // Update project with new data
      await updateProjectWithNewData(projectSelected.id, response.data.data.id);

      // Log activity
      newActivity(userId, jwt, file.name, "upload");

        // Update status to completed
        setUploadQueue((prevQueue) =>
            prevQueue.map((item) =>
            item.id === id ? { ...item, status: 'completed', progress: 100 } : item
            )
        );

      // Reload projects if necessary
      reloadProjects();

    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${t("ErrorUpload")}`, { variant: 'error' });
      // Update status to error
      setUploadQueue((prevQueue) =>
        prevQueue.map((item) =>
          item.id === id ? { ...item, status: 'error', progress: 0 } : item
        )
      );
    }
  };

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", cloudinary_preset);

    const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloudinary_cloud}/image/upload`, formData);
    return response.data.secure_url;
  };

  const createDataForm = (filename, size, description, optimizeImage = null) => {
    const dataForm = new FormData();
    const data = {
      filename: filename,
      size: size,
      description: description,
      viewable: false,
    };
    if (optimizeImage) {
      data.imageUrl = optimizeImage;
    }
    dataForm.append('data', JSON.stringify(data));
    return dataForm;
  };

  const saveData = async (dataForm) => {
    let response = await axios.post(`${global.API_URL}/api/datas`, dataForm, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    });
    return response;
  };

  const updateProjectWithNewData = async (idProject, idData) => {
    let project = await axios.get(`${global.API_URL}/api/projects/${idProject}?populate=*`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    // Add the new data ID to the project's data list
    let newDataIds = project.data.data.attributes.data.data.map((item) => item.id);
    newDataIds.push(idData);

    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
      data: newDataIds,
    }));
    await axios.put(`${global.API_URL}/api/projects/${idProject}`, dataForm, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  return (
    <>
      {isVisible && uploadQueue.length > 0 && (
        <Paper
        sx={{
            position: 'fixed',
            borderRadius: 3,
            bottom: '15px',
            right: '15px',
            width: {
              xs: '90%', // 90% width on extra-small screens
              sm: '70%', // 70% width on small screens
              md: '50%', // 50% width on medium screens
              lg: '40%', // 40% width on large screens
              xl: '30%', // 30% width on extra-large screens
            },
            maxHeight: '40vh',
            overflowY: 'auto',
            padding: 2,
            backgroundColor: 'background.paper',
            zIndex: 1300,
          }}
          elevation={24}
        >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{p:1}}>
            <Typography variant="h6">{t("UploadQueue")}</Typography>
            <Box>
            <IconButton size="small" onClick={() => setIsUploadQueueOpen(!isUploadQueueOpen)}>
                {isUploadQueueOpen ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
            {!hasOngoingUploads && (
            <IconButton size="small" onClick={() => {setUploadQueue([]);setIsVisible(false);}}>
                <Close />
            </IconButton>)}
            </Box>
        </Box>
          <Collapse in={isUploadQueueOpen}>
          {uploadQueue.map((item) => (
            <Box key={item.id} mb={1} display="flex" alignItems="center">
                {/* Icon based on status */}
                {item.status === 'completed' && (
                <CheckCircle fontSize="small" style={{ color: 'green', marginRight: '8px' }} />
                )}
                {item.status === 'error' && (
                <ErrorIcon fontSize="small" style={{ color: 'red', marginRight: '8px' }} />
                )}
                {/* Filename and progress */}
                <Box flexGrow={1}>
                <Typography variant="body2">{item.fileName}</Typography>
                <BorderLinearProgress variant="determinate" value={item.progress} />
                <Typography variant="caption" color="textSecondary">
                    {item.status === 'completed'
                    ? t("UploadCompleted")
                    : item.status === 'error'
                    ? t("UploadFailed")
                    : `${t("Uploading")}... ${item.progress}%`}
                </Typography>
                </Box>
            </Box>
            ))}
          </Collapse>
        </Paper>
      )}

      {/* This is how we'll expose the addToUploadQueue function */}
      {props.children({ addToUploadQueue })}
    </>
  );
}

export default UploadQueue;
