global.API_URL='https://api.mirukuru-online.com'
global.BUCKET='mirukuru-data'
global.CLIENTID="6e06o0d1gjb8ue8lh7ov7vojdj"
global.USERPOOLID="ap-northeast-1_0TvWRBZPp"
global.REGION="ap-northeast-1"

global.USER_CREATION_API_URL='https://i8jsygolcg.execute-api.ap-northeast-1.amazonaws.com/default/create-user'

global.DIR_PRODUCTS='products'
global.DIR_UPLOADS='uploads'
global.DIR_VIEWS='views'