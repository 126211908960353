import { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import {
    paperClasses,
  } from '@mui/material';

import backgroundImage from "../assets/img/background.webp"; 

function Copyright(props) {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link underline="hover" variant="subtitle2" style={{ color: '#6C737F' }} onClick={() => window.location.href = 'https://www.mirukuru.co.jp/'}>
                https://www.mirukuru.co.jp/
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {

    const { i18n , t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { signIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('languageMirukuru') !== null) {          
            i18n.changeLanguage(localStorage.getItem('languageMirukuru'));
        }
    }, [i18n]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        const data = new FormData(e.currentTarget);
        try {
          await signIn(data.get('email'), data.get('password'));
          setIsLoading(false);
          navigate("/")
        } catch (err) {
            console.log(err.message);
            enqueueSnackbar(`${t("ErrorLogin")}`, {variant:'error'} );
            setIsLoading(false);
        }
      }

    const content = (
        <>

            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
                sx={{ px: 3, maxWidth: '700px' }}
            >

                <Grid xs={12}>
                {/* <CardMedia
                        component="img"
                        alt="logo mirukuru"
                        width="100px"
                        image={logo}
                        sx={{ px:4, py:3 }}
                    /> */}
                    <Card sx={{  
                        p: {
                            xs: 2, // padding for extra-small screens (0px - 599px)
                            sm: 3, // padding for small screens (600px - 959px)
                            md: 5, // padding for medium screens (960px - 1279px)
                            lg: 5, // padding for large screens (1280px - 1919px)
                            xl: 5, // padding for extra-large screens (1920px and up)
                        },
                        [`&.${paperClasses.elevation1}`]: {
                        boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.1)',
                        },
                        '&:hover': {
                        boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.1)',
                        transform: 'none',
                        },
                     }}>

                        <Stack
                            spacing={4}
                            sx={{ m:3 }}
                        >
                            {/* <Typography variant="h4">
                            {t("Login")}
                            </Typography> */}
                            <CardMedia
                                component="img"
                                alt="logo"
                                image={logo}
                                sx={{   maxWidth: '400px', minWidth: '150px', mx: 'auto' }} // Adjust the width as needed
                            />
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t("DontHaveAccount")}
                                &nbsp;
                                <Link
                                    onClick={() => window.location.href = 'mailto:contact-user-guest@mirukuru.co.jp'}
                                    underline="hover"
                                    variant="subtitle2"
                                >
                                    {t("ContactUs")}
                                </Link>
                            </Typography>
                        
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    label={t("Email")}
                                    name="email"
                                    type="email"
                                />
                                <TextField
                                    fullWidth
                                    label={t("Password")}
                                    name="password"
                                    type="password"
                                />
                            </Stack>
                            <Button
                                fullWidth
                                size="large"
                                sx={{ mt: 3 }}
                                type="submit"
                                variant="contained"
                            >
                                {isLoading ? `${t("Login")} ...` : `${t("Login")}`}
                            </Button>
                        </Box>
                        <Grid
                        container
                            alignItems="center"
                            justifyContent="center">
                            <Link
                                onClick={() => window.location.href = '/reset-password'}
                                underline="hover"
                                variant="subtitle2"
                            >
                                {t("ForgotPassword")}
                            </Link>
                        </Grid>
                        </Stack>
                    </Card>
                    <Copyright sx={{ mt: 2, mb: 4 }} />
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                    width: '100%',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {content}
                </Grid>
            </Box>
        </>
    );
}