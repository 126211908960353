import axios from "axios";
import { useState,useContext } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import DownloadIcon from '@mui/icons-material/Download';
import AnimationIcon from '@mui/icons-material/Animation';
import EditIcon from '@mui/icons-material/Edit';

import ThreeDialog from "../dialog/three-dialog.js"
import EditProductDialog from "../dialog/edit-product-dialog.js"

import { AuthContext } from "../Auth/AuthContext";
import {getPresignedResultURLS3,deleteProductS3} from "../utils/interfaceS3";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';

export const ProductPopoverAdmin = (props) => {

  const {  t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { anchorEl, onClose, open, data, userid, loadProducts} = props;

  const {jwt,idToken, region, idpool, demo } = useContext(AuthContext);

  const [openDialogEditProduct, setOpenDialogEditProduct] = useState(false);

  async function handleDownloadProductClick () {
    const url = await getPresignedResultURLS3(userid,idToken, region, idpool,`${data.attributes.filename}`,3600);
    window.location.href = url;
  };

  async function handleDeleteProductClick() {
    let filepath=data.attributes.filename;
    let path = filepath.substring(filepath.lastIndexOf('/')+1)
    deleteProductS3(userid,idToken, region, idpool,path);
    const apiUrl = `${global.API_URL}/api/results/${data.id}`;
    axios.delete(apiUrl, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        }
    })
        .then(res => {
          //console.log(res)
            enqueueSnackbar(`${t("successDeleteFile")}`, {variant:'success'} );
            loadProducts();        
        })
        .catch(err => {
          //console.log(err)
            enqueueSnackbar(`${t("errorDeleteFile")}`, {variant:'error'} );
        });
    onClose();
  };

  const [urlLink, setUrlLink] = useState("");
  const [openThreeDialog, setOpenThreeDialog] = useState(false);

  async function handleClickOpenThreeDialog () {
    setOpenThreeDialog(true);
    const url = await getPresignedResultURLS3(userid,idToken, region, idpool,`${data.attributes.filename}`,86400);
    setUrlLink(url);
  };

  const handleEditProductClick = num => {
    setOpenDialogEditProduct(true);
    onClose();
  };

  const handleCloseThreeDialog = () => {
    setOpenThreeDialog(false);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 160 } }}
      >
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >
      {data.attributes.filename && data.attributes.filename.split('.').pop() === 'ply' && <MenuItem disabled={demo} onClick={(event) => handleClickOpenThreeDialog(event)}>
        <ListItemIcon>
          <AnimationIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("View")}</ListItemText>
      </MenuItem>}
      <MenuItem disabled={demo} onClick={(event) => handleEditProductClick(event)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Edit")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleDownloadProductClick(event)}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Download")}</ListItemText>
          </MenuItem>
          <MenuItem disabled={demo} onClick={(event) => handleDeleteProductClick(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Delete")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <EditProductDialog open={openDialogEditProduct} setOpen={setOpenDialogEditProduct} data={data} loadProducts={loadProducts}/>
      <ThreeDialog open={openThreeDialog} onClose={handleCloseThreeDialog} url={urlLink}/>
    </>
  );
};

ProductPopoverAdmin.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
